import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,{staticClass:"block",class:{ current: _vm.blockIndex === _vm.index },attrs:{"id":("block-" + _vm.index)}},[_c(VExpansionPanelHeader,[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.block.title))]),_c('div',{staticClass:"accentText--text"},[_vm._v(_vm._s(_vm.block.description))])])]),_c(VExpansionPanelContent,[_c('h4',[_vm._v(_vm._s(_vm.$t("seminars.tasks")))]),_c('div',_vm._l((_vm.block.elements),function(el,i){return _c('div',{key:i,staticClass:"element",class:{ last: i === _vm.block.elements.length - 1 },attrs:{"id":("element-" + _vm.index + "-" + i)},on:{"click":function($event){return _vm.selectEl(i)}}},[_c('div',{staticClass:"utils-container"},[_c('div',{staticClass:"circle",class:{
              current: _vm.isCurrentElement(_vm.index, i),
              completed: _vm.isComplete(el),
              skipped: _vm.isSkipped(el),
            }},[(_vm.isComplete(el))?_c(VIcon,{attrs:{"small":"","color":"other"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.isSkipped(el))?_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-skip-next ")]):_vm._e()],1),_c('div',{staticClass:"line",class:{ first: i === 0, completed: _vm.isComplete(el) }})]),_c('div',{staticClass:"title text-truncate",class:{ current: _vm.isCurrentElement(_vm.index, i) }},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(el.title))])])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }