
























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar, SeminarBlock, SeminarElement } from "@/core/models";
import { elementStatus } from "@/core/utils/seminars";

@Component
export default class Block extends Vue {
  @Prop({ default: () => 0 }) index!: number;

  @Getter("seminars/selected") seminar!: Seminar;
  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Getter("seminars/blockIndex") blockIndex!: number;
  @Getter("seminars/elementIndex") elementIndex!: number;
  @Getter("profile/id") userId!: string;

  @Action("seminars/setindices") set!: (d: [number, number]) => void;

  get block() {
    return this.blocks[this.index];
  }

  isCurrentElement(block: number, el: number) {
    return this.blockIndex === block && this.elementIndex === el;
  }

  isComplete(e: SeminarElement) {
    return elementStatus(e, this.seminar) === "completed";
  }

  isSkipped(e: SeminarElement) {
    return elementStatus(e, this.seminar) === "skipped";
  }

  selectEl(i: number) {
    const prevBlock = this.index - 1 < 0 ? null : this.blocks[this.index - 1];
    const lastElOfPrevBlock =
      prevBlock?.elements[prevBlock.elements.length - 1];
    const prevEl =
      i === 0 ? lastElOfPrevBlock : this.blocks[this.index].elements[i - 1];

    if (!prevEl || elementStatus(prevEl, this.seminar) !== "active")
      this.set([this.index, i]);
  }
}
