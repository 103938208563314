
































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { SeminarBlock } from "@/core/models";
import Block from "../components/Block.vue";

@Component({ components: { Block } })
export default class Sidebar extends Vue {
  @Getter("seminars/selectedId") id!: number;
  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Getter("seminars/blockIndex") blockIndex!: number;
  @Action("seminars/get") get!: (d: any) => Promise<void>;

  refreshing = false;
  async refresh() {
    this.refreshing = true;
    try {
      await this.get({ id: this.id, refresh: true });
    } catch (error) {
      console.error(error);
    }
    this.refreshing = false;
  }
}
